<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Commandes"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div v-if="state.myList.length == 0" class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10">
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune commande")}}</h4>
            <span>{{$t("Il n'existe aucune commande à afficher correspondant à vos critères de recherche.")}}</span>
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :empty-table-text="$t('Aucune commande à afficher.')"
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-pro_nom_projet="{ row: data }">
            <table style="margin-left: 15px; width: 300px">
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("Client")}}</td><td class="text-gray-800 p-1 fs-7" style="word-wrap: break-word; max-width: 210px;"><b>{{data.soc_nom_societe}}</b></td></tr>
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("Ref Devis")}}</td><td class="text-gray-600 p-1 fs-7" style="word-wrap: break-word; max-width: 210px;">{{data.toc_ref_devis_frs}}</td></tr>
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("N° BC Still'")}}</td><td class="text-gray-600 p-1 fs-7" style="word-wrap: break-word; max-width: 210px;">{{data.toc_code}}</td></tr>
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("N° BC Client")}}</td><td class="text-gray-600 p-1 fs-7" style="word-wrap: break-word; max-width: 210px;">{{data.num_cmd_client}}</td></tr>
            </table>

          </template>

          <template v-slot:cell-tof_total_ht="{ row: data }">
            <table style="margin-left: 15px; width: 250px">
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("Votre N° SO")}}</td><td class="text-gray-600 p-1 fs-7">
                <el-input v-model="data.num_cmd_client" class="w-150px" size="small" placeholder="Please Input" />
              </td></tr>
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("Votre N° PO")}}</td><td class="text-gray-600 p-1 fs-7">
                <el-input v-model="data.num_cmd_client" class="w-150px" size="small" placeholder="Please Input"/>
              </td></tr>
              <tr style="border: none"><td class="text-gray-400 fs-8 p-0 w-90px">{{$t("N° SO Cisco")}}</td><td class="text-gray-600 p-1 fs-7">
                <el-input v-model="data.num_cmd_client" class="w-150px" size="small" placeholder="Please Input" />
              </td></tr>
            </table>
          </template>
          <template v-slot:cell-date_ldc="{ row: data }">
            <el-select v-model="data.tis_status" class="mb-2 nobg w-250px" :placeholder="$t('A définir')" size="large">
              <el-option :value="data.etat_txt" :label="`${data.etat_txt == 'Commande livrée' ? '✅' : ''}${data.etat_txt == 'En cours de traitement' ? '⌛' : ''}${data.etat_txt == 'Partiellement livrée' ? '🔃' : ''} ${data.etat_txt}`"></el-option>
            </el-select>
            <br>
            <el-date-picker v-model="data.date_ldc" format="DD/MM/YYYY" value-format="YYYY-MM-DD" style="width: 250px" type="date" placeholder="Livraison estimée..."  />
            <div class="mt-2 fs-8" style="background: white; width: 250px; padding: 9px; border: 1px solid #dcdfe6; border-radius: 4px;" >
              <router-link :to="returnUrlCommande(data.toc_seq)">
                <span class="svg-icon svg-icon-2 svg-icon-success m-0">
                  <inline-svg src="media/icons/duotune/general/gen043.svg"/> </span>
              </router-link>
              <span class="fs-8">&nbsp;{{$t("Fraicheur :")}} {{ formatDateAgo(data.fraicheur) }}</span>
          </div>

          </template>
          <template v-slot:cell-fraicheur="{ row: data }">
            {{ formatDateAgo(data.fraicheur) }}
          </template>
          <template v-slot:cell-etat_txt="{ row: data }">
            <span style="cursor: default" :class="styleBtnStatus(data)" class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark w-200px">
              <span class="svg-icon svg-icon-4">
                <inline-svg :src="styleImgStatus(data)" />
              </span>
              {{ data.etat_txt && $t(data.etat_txt) }}
            </span>
            <!--
              <div class="d-flex flex-column bg-white p-2 w-200px text-gray-600" style="border-radius: 5px; padding-left:15px !important;">
                <li v-if="data.toc_etat_matos > 0" class="d-flex align-items-center text-uppercase fs-8">- Equipements</li>
                <li v-if="data.toc_etat_integration > 0" class="d-flex align-items-center text-uppercase fs-8">- Prestation</li>
                <li v-if="data.toc_etat_support > 0" class="d-flex align-items-center text-uppercase fs-8">- Support / Abonnement</li>
              </div>
            -->
          </template>
          <template v-slot:cell-toc_seq="{ row: data }">
            <router-link :to="returnUrlCommande(data.toc_seq)" class="btn btn-sm btn-light-dark me-2 mb-2">
              <span class="svg-icon svg-icon-3 svg-icon-primary">
                <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>{{$t("PDF")}}
            </router-link>
            <router-link :to="returnUrlCommande(data.toc_seq)" class="btn btn-sm btn-light-dark me-2 mb-2">
              <span class="svg-icon svg-icon-3 svg-icon-primary">
                <inline-svg src="media/icons/duotune/coding/cod009.svg" /> </span>{{$t("Livraison Partielle ?")}}
            </router-link>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Suivi des commandes fournisseur",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    //moment.default.locale("fr");
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: 'Commande',
        key: "pro_nom_projet",
        sortable: false,
      },
      {
        name: " ",
        key: "tof_total_ht",
        sortable: false,
      },
      {
        name: 'Livraison estimée',
        key: "date_ldc",
        sortable: false,
      }, 
      {
        name: 'Actions',
        key: "toc_seq",
        sortable: false,
      },
    ]);

    const tableFilters = ref([
      {
        name: 'Client',
        key: "soc_nom_societe",
        label: "soc_nom_societe",
      },
      {
        name: 'Status',
        key: "etat_txt",
        label: "etat_txt",
        default : ['En cours de traitement', 'Partiellement livrée']
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "num_cmd_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "toc_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "etat_txt",
      "Montant HT": "tof_total_ht",
      "Date LDC": "date_ldc",
      Fraicheur: "fraicheur",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (data) => {
      if (data.etat_txt == "En cours de traitement")
        return "btn-icon-dark bg-white";
      if (data.etat_txt == "Partiellement livrée")
        return "btn-icon-warning bg-white";
      if (data.etat_txt == "Commande livrée")
        return "btn-icon-success bg-white";
    };

    const styleImgStatus = (data) => {
      if (data.etat_txt == "En cours de traitement")
        return "media/icons/duotune/arrows/arr069.svg";
      if (data.etat_txt == "Partiellement livrée")
        return "media/icons/duotune/arrows/arr084.svg";
      if (data.etat_txt == "Commande livrée")
        return "media/icons/duotune/arrows/arr085.svg";
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.date_old_ldc
      )} ${t("au")} ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des commandes fournisseur", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await mAxiosApi.prototype.getAxios("/getCommandesFrs/-1/frs");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      // console.log(myList.results);

      for (
        let index = 0, l = state.initialMyList.length;
        index < l;
        index += 1
      ) {
        const item: any = state.initialMyList[index];
        item.etat_txt = "En cours de traitement";

        if (
          item.toc_etat_livraison_min == 1 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Partiellement livrée";
        }

        if (
          item.toc_etat_livraison_min == 2 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Commande livrée";
        }
      }

      state.loaderEnabled = false;
      if (router.currentRoute.value.params.code) {
        state.search = router.currentRoute.value.params.code as string;
        affDetail();
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
    };
  },
});
</script>
